import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	forwardRef,
	inject,
	signal,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AddictionComponentsModule, Asset, AssetType } from 'addiction-components';
import { first } from 'rxjs';
import { AssetReference, ResolvedAssetReference } from '../../models/asset-reference.interface';
import { AssetPreviewData } from '../../models/media-library/asset-preview-data';
import { AssetSelectorContainerComponent } from '../asset-selector-container/asset-selector-container.component';
import { AssetSelectorComponent } from '../asset-selector/asset-selector.component';

@Component({
	selector: 'datalean-structure-file-selector',
	templateUrl: './structure-file-selector.component.html',
	styleUrls: ['./structure-file-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, AddictionComponentsModule, TranslateModule, AssetSelectorComponent, MatTooltipModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: StructureFileSelectorComponent, multi: true },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => StructureFileSelectorComponent),
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StructureFileSelectorComponent implements ControlValueAccessor, Validator, OnInit {
	private dialog = inject(Dialog);
	protected _value = signal<ResolvedAssetReference | undefined>(undefined);
	get value() {
		return this._value();
	}
	protected assetType: typeof AssetType = AssetType;
	// TODO - implementare
	private _assetTypeInput?: AssetType;
	get assetTypeInput() {
		return this._assetTypeInput;
	}

	@Input() set value(val: ResolvedAssetReference | undefined | string) {
		this._value.set(this.normalizeValue(val));
		this.onValueChanged(this._value());
	}
	@Input() mode: 'complete' | 'compact' | 'image' = 'compact';
	@Input() label: string = '';
	@Input() requiredIcon?: boolean = false;
	@Input() readonly: boolean = false;
	@Input() set inputTypeRequired(input: AssetType | string | undefined) {
		if (typeof input === 'string') {
			this._assetTypeInput = input as AssetType;
			return;
		}
		this._assetTypeInput = input;
	}
	@Input() formatRequired?: string = '';
	@Input() disabled: boolean = false;
	@Input() hint?: string;

	@Output() selected = new EventEmitter<AssetReference>();

	formGroup = new FormGroup({
		uuid: new FormControl('', { nonNullable: true }),
		url: new FormControl<string>('', { nonNullable: true }),
		format: new FormControl('', { nonNullable: true }),
		name: new FormControl(),
		altText: new FormControl(),
	});

	constructor() {}

	ngOnInit(): void {
		if (this._value) {
			this.formGroup.patchValue(this._value() ?? {});
		}
		if (this.disabled) this.formGroup.disable();
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		if (this.requiredIcon === true) {
			this.formGroup.controls.url.addValidators([Validators.required, Validators.minLength(1)]);
			c.setErrors(this.formGroup.errors);
		}

		return c.invalid || this.formGroup.invalid ? { ...c.errors, ...this.formGroup.errors, structure: 1 } : null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: string | undefined): void {
		// console.log('writeValue', obj);
		const normalizeValue = this.normalizeValue(obj);
		// console.log('normalizeValue', normalizeValue);
		if (normalizeValue) {
			this.formGroup.patchValue(normalizeValue);
		} else {
			this.formGroup.reset();
		}
		this._value.set(normalizeValue);
	}

	registerOnChange(fn: (val: unknown | undefined) => void): void {
		this.formGroup.valueChanges.subscribe(fn);
	}

	setDisabledState(disabled: boolean) {
		if (disabled) this.formGroup.disable({ emitEvent: false });
		else this.formGroup.enable({ emitEvent: false });
	}

	chooseAsset() {
		const dialog = this.dialog.open<Asset>(AssetSelectorContainerComponent, {
			data: {
				selectionMode: 'single',
				assetType: this._assetTypeInput,
				formatRequired: this.formatRequired,
			},
			height: '100%',
			width: '100%',
			maxWidth: '100%',
			maxHeight: '100%',
			hasBackdrop: false,
		});
		return dialog.closed.pipe(first()).subscribe((result) => {
			if (result) {
				this.onValueChanged({
					format: result.format,
					uuid: result.uuid,
					preview: result.preview,
					url: result.url,
					name: result.name,
				});
			}
		});
	}

	removeAsset() {
		this.value = undefined;
		this.onValueChanged();
	}

	protected assetSelected(evt?: AssetPreviewData | string) {
		if (typeof evt === 'string') throw "Cannot assign a string value to StructureFileSelector component. Make sure 'emit' is set to 'object'";

		this._value.set({ ...(evt as ResolvedAssetReference) });

		this.onValueChanged(evt);
	}

	protected onValueChanged(val?: AssetPreviewData) {
		// console.log('onValueChanged', val);
		if (val) {
			this.formGroup.controls.altText.enable();
			this.formGroup.patchValue(val);
		} else {
			this.formGroup.reset();
			this.formGroup.controls.altText.disable();
			this._value.set(undefined);
		}
	}

	protected normalizeValue(val: ResolvedAssetReference | undefined | string): ResolvedAssetReference | undefined {
		if (!val) return undefined;
		if (typeof val === 'string') return { uuid: val, url: '', format: '' };
		if (!val.url?.length) return undefined;
		return { ...val };
	}
}
