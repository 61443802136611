import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { ChallengeActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ChallengeService } from '../../services/challenge.service';
import { deleteChallenge, setChallengesSuccess, setDeleteChallengesError, setDeleteChallengesSuccess } from './challenge.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './challenge.selectors';
import { Router } from '@angular/router';

@Injectable()
export class ChallengeEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private challengeService = inject(ChallengeService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router);

	fetchChallenges$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ChallengeActions.setPages, ChallengeActions.setForceRefreshChallenges),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(ChallengeActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.challengeService.fetchChallenges(actualPages, sort, this.translateService.currentLang, gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalChallengesCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.challenges[item.paginationInfo.pageNumber] = item.result.map((community) => ({
										...community,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));
						return ChallengeActions.setChallengesSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ChallengeActions.setChallengesError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChallengeActions.setGridSearch, setDeleteChallengesSuccess, ChallengeActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => ChallengeActions.resetCache())
		)
	);

	deleteChallenges$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteChallenge),
			switchMap(({ uuid }) => {
				return this.challengeService.deleteChallenge(uuid).pipe(
					map(() => {
						return setDeleteChallengesSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteChallengesError({ error }));
					})
				);
			})
		)
	);

	challengeSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setChallengesSuccess),
			map(({ data: { pages } }) => ChallengeActions.setCachedPages({ pages }))
		)
	);

	afterDeleteChallenge$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChallengeActions.setDeleteChallengesError, ChallengeActions.setDeleteChallengesSuccess),
			map(() => ChallengeActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChallengeActions.resetCache),
			map(() => ChallengeActions.setForceRefreshChallenges())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('challenges')),
			map(() => ChallengeActions.resetCache())
		)
	);

	constructor() {}
}
