import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, ContentsActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors, ContentsSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { ContentService } from '../services/content.service';
import { setContentsSuccess } from './content.actions';
import { selectPagedData } from './content.selectors';

@Injectable()
export class ContentEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private contentService = inject(ContentService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);
	private activeRoute = inject(Router);

	fetchContents$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ContentsActions.setPages, ContentsActions.setForceRefreshContents),
			concatLatestFrom(() => [
				this.store.select(ContentsSelectors.selectPages),
				this.store.select(ContentsSelectors.selectSort),
				this.store.select(ContentsSelectors.selectGridSearch),
				this.store.select(ContentsSelectors.selectContentsFilters),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(ContentsSelectors.selectCachedPages),
			]),
			debounce(() => this.store.select(ContentsSelectors.selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(ContentsActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) {
					this.store.dispatch(ContentsActions.setStatus({ status: STATE_STATUS.READY }));
					return EMPTY;
				}
				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;

				return this.contentService.fetchContents(actualPages, sort, this.translateService.currentLang, gridSearch, filterWithCommunity).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalContentsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.contents[item.paginationInfo.pageNumber] = item.result.map((content) => ({
										...content,
										features: content.featureValueList.map((f) => (typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name))),
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return ContentsActions.setContentsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ContentsActions.setContentsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ContentsActions.setGridSearch,
				ContentsActions.setSort,
				ContentsActions.updateFilters,
				ContentsActions.addFeatureValueToFilters,
				ContentsActions.removeFeatureValueFromFilters
			),
			concatLatestFrom(() => [
				this.store.select(ContentsSelectors.selectPages),
				this.store.select(ContentsSelectors.selectSort),
				this.store.select(ContentsSelectors.selectGridSearch),
				this.store.select(ContentsSelectors.selectContentsFilters),
				this.store.select(ContentsSelectors.selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, filterPrev], [, pagesCurr, sortCurr, gridSearchCurr, filterCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						Object.values(filterPrev).every((f) => Object.values(filterCurr).includes(f)) &&
						Object.values(filterCurr).every((f) => Object.values(filterPrev).includes(f)) &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => ContentsActions.resetCache())
		)
	);

	deleteContents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContentsActions.deleteContents),
			// invoke API
			switchMap(({ uuids }) =>
				this.contentService
					.multipleUpdate({
						uuidList: uuids,
						delete: true,
					})
					.pipe(
						map(() => ContentsActions.setDeleteContentsSuccess()),
						catchError((error: HttpErrorResponse) => of(ContentsActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.contentService.getStructureAndFeatures())
					)
			)
		)
	);

	disableContents$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContentsActions.disableContents),
			// invoke API
			switchMap(({ uuids }) =>
				this.contentService
					.multipleUpdate({
						uuidList: uuids,
						active: false,
					})
					.pipe(
						map(() => ContentsActions.setDisableContentsSuccess()),
						catchError((error: HttpErrorResponse) => of(ContentsActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.contentService.getStructureAndFeatures())
					)
			)
		)
	);

	contentSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setContentsSuccess),
			map(({ data: { pages } }) => ContentsActions.setCachedPages({ pages }))
		)
	);

	deleteAndDisableContent$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ContentsActions.setDeleteContentsSuccess,
				ContentsActions.setDeleteContentsError,
				ContentsActions.setDisableContentsSuccess,
				ContentsActions.setDisableContentsError
			),
			map(() => ContentsActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContentsActions.resetCache),
			tap(() => {
				this.store.dispatch(ContentsActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			debounceTime(500),
			map(() => ContentsActions.setForceRefreshContents()),
			tap(() => {
				this.store.dispatch(ContentsActions.setStatus({ status: STATE_STATUS.READY }));
			})
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/contents'),
			map(() => ContentsActions.resetCache())
		)
	);

	constructor() {}
}
