import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { CommunitySelectActions, SurveysActions } from '../../../core/state/app.actions';
import { SurveysService } from '../services/surveys.service';
import { setDeleteSurvey, setDeleteSurveysSuccess, setSurveysError, setSurveysSuccess } from './surveys.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './surveys.selectors';
import { Router } from '@angular/router';

@Injectable()
export class SurveysEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private surveysService = inject(SurveysService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router);

	constructor() {}

	fetchSurvey$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(SurveysActions.setPages, SurveysActions.setForceRefreshSurveys),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(SurveysActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, communityUUID, cachedPages]) => {
				// console.log(pages, cachedPages);
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.surveysService
					.fetchSurveys(actualPages, sort, this.translateService.currentLang ?? this.translateService.defaultLang, gridSearch, communityUUID)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalSurveyCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.surveys[item.paginationInfo.pageNumber] = item.result;
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return SurveysActions.setSurveysSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(SurveysActions.setSurveysError({ error }));
						})
					);
			})
		)
	);

	deleteSurveys$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteSurvey),
			switchMap(({ survey }) => {
				return this.surveysService.deleteSurvey(survey).pipe(
					map(() => {
						return setDeleteSurveysSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setSurveysError({ error }));
					})
				);
			})
		)
	);

	surveySuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setSurveysSuccess),
			map(({ data: { pages } }) => SurveysActions.setCachedPages({ pages }))
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SurveysActions.setGridSearch, SurveysActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
					// console.log(action);
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => SurveysActions.resetCache())
		)
	);

	deleteSurvey$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SurveysActions.setDeleteSurveysError, SurveysActions.setDeleteSurveysSuccess),
			map(() => SurveysActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SurveysActions.resetCache),
			map(() => SurveysActions.setForceRefreshSurveys())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('/surveys')),
			map(() => SurveysActions.resetCache())
		)
	);
	
}
