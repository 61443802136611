import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { CommunitySelectActions, ContestActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ContestService } from '../../services/contest.service';
import { deleteContest, setDeleteContestsError, setDeleteContestsSuccess } from './contest.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './contest.selectors';
import { Router } from '@angular/router';

@Injectable()
export class ContestEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private contestService = inject(ContestService);
	private headerSrv = inject(HeaderLocaleService);
	private activeRoute = inject(Router);

	fetchContests$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(ContestActions.setPages, ContestActions.setForceRefreshContests),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),

			tap(() => {
				this.store.dispatch(ContestActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));

				if (!actualPages.length) return EMPTY;

				return this.contestService.fetchContests(actualPages, sort, this.headerSrv.getActiveLocale(), gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalContestsCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.contests[item.paginationInfo.pageNumber] = item.result.map((contest) => ({
										...contest,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));
						return ContestActions.setContestsSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ContestActions.setContestsError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContestActions.setGridSearch, ContestActions.setSort),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(([, pagesPrev, sortPrev, gridSearchPrev], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages]) => {
				return (
					pagesPrev.length === pagesCurr.length &&
					pagesPrev.every((f) => pagesCurr.includes(f)) &&
					sortCurr?.active === sortPrev?.active &&
					sortCurr?.direction === sortPrev?.direction &&
					gridSearchCurr === gridSearchPrev &&
					pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
				);
			}),
			map(() => ContestActions.resetCache())
		)
	);

	deleteContest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteContest),
			switchMap(({ uuid }) => {
				return this.contestService.deleteContest(uuid).pipe(
					map(() => {
						return setDeleteContestsSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteContestsError({ error }));
					})
				);
			})
		)
	);

	contestSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContestActions.setContestsSuccess),
			map(({ data: { pages } }) => ContestActions.setCachedPages({ pages }))
		)
	);

	afterDeleteContenst$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContestActions.setDeleteContestsSuccess, ContestActions.setDeleteContestsError),
			map(() => ContestActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContestActions.resetCache),
			map(() => ContestActions.setForceRefreshContests())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/contests'),
			map(() => ContestActions.resetCache())
		)
	);

	constructor() {}
}
