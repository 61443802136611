import { BaseEnvironment } from 'src/app/shared/models/environment.interface';

export const baseEnvironment: BaseEnvironment = {
	production: true,

	// authenticationUrl: 'http://localhost:3020/',
	// usersUrl: 'http://localhost:3002/user/',
	// surveyUrl: 'http://localhost:3010/survey/v2/',
	// surveyResponseUrl: 'http://localhost:3010/response/v2/',
	// groupsUrl: 'http://localhost:3002/user/applicationGroup/',
	// smartGroupsUrl: 'http://localhost:3002/user/applicationSmartGroup/',
	// contestUrl: 'http://localhost:3012/contest/v2/',
	// tableUsersUrl: 'http://localhost:3002/user/application/',
	// applicationUsersUrl: 'http://localhost:3002/user/application/',
	// productUrl: 'http://localhost:3006/product/v2/',
	// contentUrl: 'http://localhost:3006/content/v2/',
	// communicationUrl: 'http://localhost:3006/communication/v2/',
	// communityUrl: 'http://localhost:3009/community/',
	// collectionsUrl: 'http://localhost:3013/album/v2/',
	// mediaLibraryUrl: 'http://localhost:3013/media-library/v2/',
	// operationPermissionUrl: 'http://localhost:3013/media-library/v2/operationPermission/',
	// conversionUrl: 'https://localhost:3013/transformation/v2/',
	// tagUrl: 'http://localhost:3013/tag/v2/',
	// thumbnailConfigUrl: 'http://localhost:3013/thumbnail-config/v2/',
	// structureUrl: 'http://localhost:3003/structure/v2/',
	// structureFieldUrl: 'http://localhost:3003/structureField/v2/',
	// featureValueUrl: 'http://localhost:3007/featureValue/v2/',
	// organizationUrl: 'http://localhost:3001/organization/',
	// configurationUrl: 'http://localhost:3004/config/',
	// rolesUrl: 'http://localhost:3002/role/',
	// permissionUrl: 'http://localhost:3002/permission/',
	// userStructureUrl: 'http://localhost:3003/userstructure/',
	// wishlistUrl: 'http://localhost:3006/wishlist/',
	// templateUrl: 'http://localhost:3005/template/',
	// integrationUrl: 'http://localhost:3004/integration/',
	// filterUrl: 'http://localhost:3008/filter/',
	// lmsUrl: 'http://localhost:3018/',
	// reservationUrl: 'http://localhost:3016/reservation/v2/',
	// reservationProductUrl: 'http://localhost:3016/product/v2/',
	// reservationResourceUrl: 'http://localhost:3016/resource/v2/',
	// challengeUrl: 'http://localhost:3012/challenge',
	// contestActionUrl: 'http://localhost:3012/contest-action',
	// workflowUrl: 'http://localhost:3017/workflow/',
	// workflowEntityUrl: 'http://localhost:3017/workflow-entity/',

	authenticationUrl: 'https://auth.datalean-nodejs-preprod.catalean.com/',
	usersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/',
	surveyUrl: 'https://survey.datalean-nodejs-preprod.catalean.com/survey/v2/',
	surveyResponseUrl: 'https://survey.datalean-nodejs-preprod.catalean.com/response/v2/',
	groupsUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/applicationGroup/',
	smartGroupsUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/applicationSmartGroup/',
	contestUrl: 'https://contest.datalean-nodejs-preprod.catalean.com/contest/v2/',
	challengeUrl: 'https://contest.datalean-nodejs-preprod.catalean.com/challenge/',
	contestActionUrl: 'https://contest.datalean-nodejs-preprod.catalean.com/contest-action/v2/',
	tableUsersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/smartcommunity/',
	applicationUsersUrl: 'https://user.datalean-nodejs-preprod.catalean.com/user/application/v2/',
	productUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/product/v2/',
	contentUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/content/v2/',
	communicationUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/communication/v2/',
	communityUrl: 'https://community.datalean-nodejs-preprod.catalean.com/community/',
	structureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/v2/',
	structureFieldUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structureField/v2/',
	featureValueUrl: 'https://feature.datalean-nodejs-preprod.catalean.com/featureValue/v2/',
	organizationUrl: 'https://organization.datalean-nodejs-preprod.catalean.com/organization/',
	configurationUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/config/',
	rolesUrl: ' https://user.datalean-nodejs-preprod.catalean.com/role/',
	permissionUrl: ' https://user.datalean-nodejs-preprod.catalean.com/permission/',
	userStructureUrl: 'https://structure.datalean-nodejs-preprod.catalean.com/structure/v2/',
	wishlistUrl: 'https://core-entities.datalean-nodejs-preprod.catalean.com/wishlist/',
	templateUrl: 'https://translator.datalean-nodejs-preprod.catalean.com/template',
	integrationUrl: 'https://configuration.datalean-nodejs-preprod.catalean.com/integration/',
	filterUrl: 'https://filter.datalean-nodejs-preprod.catalean.com/filter/',
	privacyUrl: 'https://addiction.it/privacy-policy/',
	conditionTermUrl: 'https://addiction.it/privacy-policy/',
	collectionsUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/album/v2/',
	mediaLibraryUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/media-library/v2/',
	operationPermissionUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/media-library/v2/operationPermission/',
	thumbnailConfigUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/thumbnail-config/v2/',
	conversionUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/transformation/v2/',
	tagUrl: 'https://media-library.datalean-nodejs-preprod.catalean.com/tag/v2/',
	lmsUrl: 'https://lms.datalean-nodejs-preprod.catalean.com/',
	availabilityRuleUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/availability-rule/',
	reservationUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/reservation/v2/',
	reservationProductUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/product/v2/',
	reservationResourceUrl: 'https://reservation.datalean-nodejs-preprod.catalean.com/resource/v2/',
	workflowUrl: 'https://workflow.datalean-nodejs-preprod.catalean.com/workflow/',
	workflowEntityUrl: 'https://workflow.datalean-nodejs-preprod.catalean.com/workflow-entity/',
	informedConsentUrl: 'https://consenso-informato-module.datalean-nodejs-preprod.catalean.com/',

	cataleanUrl: '',
	orgLogoUrl: '',
	version: '1.0',
	htmlEditorMenu: [
		['bold', 'italic', 'underline', 'strike'],
		['ordered_list', 'bullet_list'],
		[{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
		['link'],
		['text_color', 'background_color'],
		['align_left', 'align_center', 'align_right', 'align_justify'],
	],
	sortOptions: [
		{
			value: 'name#asc',
			label: [
				{ language: 'it-IT', value: 'Nome ASC' },
				{ language: 'en-US', value: 'Name ASC' },
			],
		},
		{
			value: 'name#desc',
			label: [
				{ language: 'it-IT', value: 'Nome DESC' },
				{ language: 'en-US', value: 'Name DESC' },
			],
		},
		{
			value: 'bytes#asc',
			label: [
				{ language: 'it-IT', value: 'Dimensione ASC' },
				{ language: 'en-US', value: 'Size ASC' },
			],
		},
		{
			value: 'bytes#desc',
			label: [
				{ language: 'it-IT', value: 'Dimensione DESC' },
				{ language: 'en-US', value: 'Size DESC' },
			],
		},
		{
			value: 'updateDate#asc',
			label: [
				{ language: 'it-IT', value: 'Data ultima modifica ASC' },
				{ language: 'en-US', value: 'Update date ASC' },
			],
		},
		{
			value: 'updateDate#desc',
			label: [
				{ language: 'it-IT', value: 'Data ultima modifica DESC' },
				{ language: 'en-US', value: 'Update date DESC' },
			],
		},
		{
			value: 'createDate#asc',
			label: [
				{ language: 'it-IT', value: 'Data Creazione ASC' },
				{ language: 'en-US', value: 'Create Date ASC' },
			],
		},
		{
			value: 'createDate#desc',
			label: [
				{ language: 'it-IT', value: 'Data Creazione DESC' },
				{ language: 'en-US', value: 'Create Date DESC' },
			],
		},
	],
	pageSize: 30,
};
