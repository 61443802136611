<form class="flex items-center gap-2 mt-2">
	<input
		class="cursor-pointer rounded w-10 h-10"
		[ngClass]="{ 'cursor-not-allowed': readonly }"
		type="color"
		id="color-picker"
		(click)="handleInputColorClick($event)"
		(input)="onColorPicked($event)"
		[formControl]="colorPickerFormControl" />
	<label class="cursor-pointer" for="color-picker">Selected Color</label>
</form>
