import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, ResourceActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { ResourceService } from '../services/resource.service';
import { deleteResource, setDeleteResourcesError, setDeleteResourcesSuccess, setResourcesSuccess } from './resource.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectResourcesFilters,
	selectSort,
	selectStatus,
} from './resource.selectors';
import { CommunitySelectSelectors } from '../../../../core/state/app.selectors';
import { Router } from '@angular/router';

@Injectable()
export class ResourceEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private resourceService = inject(ResourceService);
	private translateService = inject(TranslateService);
	private activeRoute = inject(Router);

	fetchResources$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourceActions.setPages, ResourceActions.setForceRefreshResources),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectResourcesFilters),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			tap(() => this.store.dispatch(ResourceActions.setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.resourceService.fetchResources(actualPages, sort, this.translateService.currentLang, gridSearch, filters, communityUUID).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.pageNumber);
								acc.totalResourcesCount = item.paginationInfo.totalElements ?? 0;
								if (item.result) {
									acc.resources[item.paginationInfo.pageNumber] = item.result.map((resource) => ({
										...resource,
									}));
								}
							}
							return acc;
						}, structuredClone(startingResult));
						return ResourceActions.setResourcesSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						return of(ResourceActions.setResourcesError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourceActions.setGridSearch, ResourceActions.setSort, ResourceActions.updateFilters),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectResourcesFilters),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, { featureValue, active, structureUUID }],
					[
						,
						pagesCurr,
						sortCurr,
						gridSearchCurr,
						{ featureValue: featureValueCurr, active: activeCurr, structureUUID: structureUUIDCurr },
						cachedPages,
					]
				) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						featureValueCurr?.length === featureValue?.length &&
						activeCurr === active &&
						structureUUIDCurr === structureUUID &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => ResourceActions.resetCache())
		)
	);

	deleteResource$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteResource),
			switchMap(({ uuid }) => {
				return this.resourceService.deleteResource(uuid).pipe(
					map(() => {
						return setDeleteResourcesSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteResourcesError({ error }));
					})
				);
			})
		)
	);

	resourceSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setResourcesSuccess),
			map(({ data: { pages } }) => ResourceActions.setCachedPages({ pages }))
		)
	);

	afterDelete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourceActions.setDeleteResourcesSuccess, ResourceActions.setDeleteResourcesError),
			map(() => ResourceActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ResourceActions.resetCache),
			map(() => ResourceActions.setForceRefreshResources())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/resources'),
			map(() => ResourceActions.resetCache())
		)
	);

	constructor() {}
}
