<div class="w-full">
	<form class="w-full">
		<div
			class="flex items-center content-center flex-wrap gap-2 flex-row bg-[#2C3446] min-h-14 px-2 py-3 mt-1 rounded editor-menu"
			[ariaDisabled]="readonly"
			[ngClass]="{ 'cursor-not-allowed': readonly }">
			<button
				id="bold"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('bold') }"
				(click)="editor.chain().focus().toggleBold().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M6 12H12.5C14.9853 12 17 9.98528 17 7.5C17 5.01472 14.9853 3 12.5 3H6V12ZM6 12H13.5C15.9853 12 18 14.0147 18 16.5C18 18.9853 15.9853 21 13.5 21H6V12Z"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				id="italic"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded italic p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('italic') }"
				(click)="editor.chain().focus().toggleItalic().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M10 3H20M4 21H14M15 3L9 21" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				id="underline"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded underline p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('underline') }"
				(click)="editor.chain().focus().toggleUnderline().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M4 21H20M18 4V11C18 14.3137 15.3137 17 12 17C8.68629 17 6 14.3137 6 11V4M4 3H8M16 3H20"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				id="strike"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded line-through p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('strike') }"
				(click)="editor.chain().focus().toggleStrike().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M3 12H21M19 7.5V7.3125C19 4.93077 17.0692 3 14.6875 3H9.5C7.01472 3 5 5.01472 5 7.5C5 9.98528 7.01472 12 9.5 12H14.5C16.9853 12 19 14.0147 19 16.5C19 18.9853 16.9853 21 14.5 21H9.40625C6.97274 21 5 19.0273 5 16.5938V16.5"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				id="orderedList"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('orderedList') }"
				(click)="editor.chain().focus().toggleOrderedList().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M10 6L21 6.00066M10 12L21 12.0007M10 18L21 18.0007M3 5L5 4V10M5 10H3M5 10H7M7 20H3L6.41274 17.0139C6.78593 16.6873 7 16.2156 7 15.7197C7 14.7699 6.23008 14 5.28033 14H5C4.06808 14 3.28503 14.6374 3.06301 15.5"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				id="bulletList"
				class="w-7 h-7 hover:backdrop-brightness-150 rounded p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('bulletList') }"
				(click)="editor.chain().focus().toggleBulletList().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							d="M8 6L21 6.00078M8 12L21 12.0008M8 18L21 18.0007M3 6.5H4V5.5H3V6.5ZM3 12.5H4V11.5H3V12.5ZM3 18.5H4V17.5H3V18.5Z"
							stroke="#ffffff"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>

			<div class="relative inline-block text-center">
				<button
					id="heading-dropdown-btn"
					(click)="isHeadingMenuOpen = !isHeadingMenuOpen"
					class="flex items-center hover:text-gray-900 focus:outline-none hover:backdrop-brightness-150 active:backdrop-brightness-150 p-1 rounded">
					<span>{{ editor.getAttributes('heading')['level'] ? 'Header ' + editor.getAttributes('heading')['level'] : 'Heading' }}</span>
					<svg class="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
					</svg>
				</button>

				<div
					id="heading-dropdown"
					*ngIf="isHeadingMenuOpen"
					(click)="isHeadingMenuOpen = false"
					class="block absolute right-0 z-20 mt-2 w-max bg-brand-color-12 rounded-md shadow-lg max-h-32 overflow-y-auto">
					<div class="py-1">
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 1 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 1 }).run()">
							Header 1
						</button>
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 2 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 2 }).run()">
							Header 2
						</button>
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 3 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 3 }).run()">
							Header 3
						</button>
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 4 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 4 }).run()">
							Header 4
						</button>
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 5 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 5 }).run()">
							Header 5
						</button>
						<button
							class="block w-full text-center px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-brand-color-12"
							[ngClass]="{ 'backdrop-brightness-150': editor.isActive('heading', { level: 6 }) }"
							(click)="this.editor.chain().focus().toggleHeading({ level: 6 }).run()">
							Header 6
						</button>
					</div>
				</div>
			</div>

			<div class="relative inline-block text-left">
				<div>
					<button
						id="link-dropdown-btn"
						class="flex items-center hover:backdrop-brightness-150 p-1 rounded"
						[ngClass]="{ 'backdrop-brightness-150': editor.isActive('link') }"
						(click)="isLinkMenuOpen = !isLinkMenuOpen">
						<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
							<g id="SVGRepo_iconCarrier">
								<path
									d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16"
									stroke="#ffffff"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"></path>
							</g>
						</svg>
					</button>
				</div>

				<div
					id="link-dropdown"
					*ngIf="isLinkMenuOpen"
					class="absolute left-1/2 transform -translate-x-1/2 z-20 mt-2 w-56 rounded shadow-lg bg-brand-color-12">
					<div class="p-4 flex flex-col space-y-4">
						<div>
							<label for="url" class="block text-xs font-medium">URL</label>
							<input
								type="url"
								id="url"
								class="mt-1 block w-full rounded px-1 mb-1 h-7 bg-[#ffffff26] placeholder:text-neutral-100"
								placeholder="Enter URL"
								#linkUrl />
						</div>
						<button class="rounded w-full py-1 bg-[#ffffff26]" (click)="setLink(linkUrl.value)">Set Link</button>
					</div>
				</div>
			</div>

			<button (click)="openTextColorPicker()" class="w-7 h-7 flex items-center p-1 rounded hover:backdrop-brightness-150">
				<input #textColorInput type="color" class="w-0 h-0" (input)="onTextColorChange($event)" />
				<svg
					fill="#ffffff"
					width="28px"
					height="28px"
					viewBox="0 0 36 36"
					version="1.1"
					preserveAspectRatio="xMidYMid meet"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<title>text-color-line</title>
						<path
							d="M19.47,3.84a1.45,1.45,0,0,0-1.4-1H18a1.45,1.45,0,0,0-1.42,1L8.42,21.56a1.35,1.35,0,0,0-.14.59,1,1,0,0,0,1,1,1.11,1.11,0,0,0,1.08-.77l2.08-4.65h11l2.08,4.59a1.24,1.24,0,0,0,1.12.83,1.08,1.08,0,0,0,1.08-1.08,1.59,1.59,0,0,0-.14-.57ZM13.36,15.71,18,5.49l4.6,10.22Z"
							class="clr-i-outline clr-i-outline-path-1"></path>
						<rect
							x="4.06"
							y="25"
							width="28"
							height="8"
							rx="2"
							ry="2"
							class="clr-i-outline clr-i-outline-path-2"
							[style.fill]="editor.getAttributes('textStyle')['color']"></rect>
						<rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect>
					</g>
				</svg>
			</button>

			<button
				(click)="openHighlightColorPicker()"
				class="w-7 h-7 flex items-center p-1 rounded hover:backdrop-brightness-150"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('highlight') }">
				<input #highlightColorInput type="color" class="w-0 h-0" (input)="onHighlightColorChange($event)" />
				<svg fill="#ffffff" width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<path
						d="m20.707 5.826-3.535-3.533a.999.999 0 0 0-1.408-.006L7.096 10.82a1.01 1.01 0 0 0-.273.488l-1.024 4.437L4 18h2.828l1.142-1.129 3.588-.828c.18-.042.345-.133.477-.262l8.667-8.535a1 1 0 0 0 .005-1.42zm-9.369 7.833-2.121-2.12 7.243-7.131 2.12 2.12-7.242 7.131"></path>
					<path [style.fill]="editor.getAttributes('highlight')['color']" d="M4 20h16v2H4"></path>
				</svg>
			</button>

			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive({ textAlign: 'left' }) }"
				(click)="editor.chain().focus().setTextAlign('left').run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M3 10H16M3 14H21M3 18H16M3 6H21" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive({ textAlign: 'center' }) }"
				(click)="editor.chain().focus().setTextAlign('center').run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M3 6H21M3 14H21M17 10H7M17 18H7" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive({ textAlign: 'right' }) }"
				(click)="editor.chain().focus().setTextAlign('right').run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M8 10H21M3 14H21M8 18H21M3 6H21" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive({ textAlign: 'justify' }) }"
				(click)="editor.chain().focus().setTextAlign('justify').run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M3 10H21M3 14H21M3 18H21M3 6H21" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
					</g>
				</svg>
			</button>
			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('superscript') }"
				(click)="editor.chain().focus().toggleSuperscript().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M17.3181 6.04842C17.6174 5.75945 18.1021 5.79767 18.3524 6.12997C18.5536 6.39707 18.5353 6.76978 18.3088 7.01579L15.2643 10.3227C14.9955 10.6147 14.9248 11.0382 15.0842 11.4017C15.2437 11.7652 15.6031 12 16 12H20C20.5523 12 21 11.5523 21 11C21 10.4477 20.5523 10 20 10H18.2799L19.7802 8.37041C20.6607 7.41399 20.7321 5.96504 19.95 4.92665C18.9769 3.63478 17.0925 3.48621 15.929 4.60962L15.3054 5.21165C14.9081 5.59526 14.897 6.22833 15.2806 6.62564C15.6642 7.02296 16.2973 7.03406 16.6946 6.65045L17.3181 6.04842ZM4.7433 8.33104C4.37384 7.92053 3.74155 7.88725 3.33104 8.25671C2.92053 8.62616 2.88726 9.25845 3.25671 9.66896L7.15465 14L3.25671 18.331C2.88726 18.7415 2.92053 19.3738 3.33104 19.7433C3.74155 20.1128 4.37384 20.0795 4.7433 19.669L8.50001 15.4948L12.2567 19.669C12.6262 20.0795 13.2585 20.1128 13.669 19.7433C14.0795 19.3738 14.1128 18.7415 13.7433 18.331L9.84537 14L13.7433 9.66896C14.1128 9.25845 14.0795 8.62616 13.669 8.25671C13.2585 7.88725 12.6262 7.92053 12.2567 8.33104L8.50001 12.5052L4.7433 8.33104Z"
							fill="#ffffff"></path>
					</g>
				</svg>
			</button>
			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('subscript') }"
				(click)="editor.chain().focus().toggleSubscript().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M4.7433 5.33104C4.37384 4.92053 3.74155 4.88726 3.33104 5.25671C2.92053 5.62617 2.88726 6.25846 3.25671 6.66897L7.15465 11L3.25671 15.331C2.88726 15.7416 2.92053 16.3738 3.33104 16.7433C3.74155 17.1128 4.37384 17.0795 4.7433 16.669L8.50001 12.4949L12.2567 16.669C12.6262 17.0795 13.2585 17.1128 13.669 16.7433C14.0795 16.3738 14.1128 15.7416 13.7433 15.331L9.84537 11L13.7433 6.66897C14.1128 6.25846 14.0795 5.62617 13.669 5.25671C13.2585 4.88726 12.6262 4.92053 12.2567 5.33104L8.50001 9.50516L4.7433 5.33104ZM17.3181 14.0484C17.6174 13.7595 18.1021 13.7977 18.3524 14.13C18.5536 14.3971 18.5353 14.7698 18.3088 15.0158L15.2643 18.3227C14.9955 18.6147 14.9248 19.0382 15.0842 19.4017C15.2437 19.7652 15.6031 20 16 20H20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18H18.2799L19.7802 16.3704C20.6607 15.414 20.7321 13.965 19.95 12.9267C18.9769 11.6348 17.0925 11.4862 15.929 12.6096L15.3054 13.2116C14.9081 13.5953 14.897 14.2283 15.2806 14.6256C15.6642 15.023 16.2973 15.0341 16.6946 14.6505L17.3181 14.0484Z"
							fill="#ffffff"></path>
					</g>
				</svg>
			</button>

			<button
				class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1"
				[ngClass]="{ 'backdrop-brightness-150': editor.isActive('table') }"
				(click)="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							stroke="#ffffff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M3 8V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2M3 8v6m0-6h6m12 0v4m0-4H9m-6 6v4a2 2 0 0 0 2 2h4m-6-6h6m0-6v6m0 0h4a2 2 0 0 0 2-2V8m-6 6v6m0 0h2m7-5v3m0 0v3m0-3h3m-3 0h-3"></path>
						<path fill="#ffffff" d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2H3V6z"></path>
					</g>
				</svg>
			</button>

			@if (editor.isActive('table')) {
			<button class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1" (click)="editor.chain().focus().addRowAfter().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							stroke="#ffffff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M3 3v3m18-3v3m0 0v4a2 2 0 0 1-2 2H9m12-6H9M3 6v4a2 2 0 0 0 2 2h4M3 6h6m0-3v3m0 0v6m6-9v9m-3 3v3m0 0v3m0-3h3m-3 0H9"></path>
					</g>
				</svg>
			</button>
			<button class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1" (click)="editor.chain().focus().addColumnAfter().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							stroke="#ffffff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M3 3h3M3 21h3m0 0h4a2 2 0 0 0 2-2V9M6 21V9m0-6h4a2 2 0 0 1 2 2v4M6 3v6M3 9h3m0 0h6m-9 6h9m3-3h3m0 0h3m-3 0v3m0-3V9"></path>
					</g>
				</svg>
			</button>
			<button class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1" (click)="editor.chain().focus().deleteRow().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							stroke="#ffffff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M3 3v3m18-3v3m0 0v4a2 2 0 0 1-2 2H9m12-6H9M3 6v4a2 2 0 0 0 2 2h4M3 6h6m0-3v3m0 0v6m6-9v9m-6 3 3 3m0 0 3 3m-3-3 3-3m-3 3-3 3"></path>
					</g>
				</svg>
			</button>
			<button class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1" (click)="editor.chain().focus().deleteColumn().run()">
				<svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path
							stroke="#ffffff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M3 3h3M3 21h3m0 0h4a2 2 0 0 0 2-2V9M6 21V9m0-6h4a2 2 0 0 1 2 2v4M6 3v6M3 9h3m0 0h6m-9 6h9m3-6 3 3m0 0 3 3m-3-3 3-3m-3 3-3 3"></path>
					</g>
				</svg>
			</button>
			<button class="w-7 h-7 hover:backdrop-brightness-150 rounded font-bold p-1" (click)="editor.chain().focus().deleteTable().run()">
				<svg
					width="20px"
					height="20px"
					viewBox="0 0 24 24"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					fill="#ffffff"
					stroke="#ffffff">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="ic_fluent_table_delete_24_regular" fill="#ffffff" fill-rule="nonzero">
								<path
									d="M17.5,12 C20.5376,12 23,14.4624 23,17.5 C23,20.5376 20.5376,23 17.5,23 C14.4624,23 12,20.5376 12,17.5 C12,14.4624 14.4624,12 17.5,12 Z M17.75,3 C19.5449,3 21,4.45507 21,6.25 L21,12.0218 C20.5368,11.7253 20.0335,11.4858 19.5,11.3135 L19.5,10 L15.5,10 L15.5,11.3135 C14.9665,11.4858 14.4632,11.7253 14,12.0218 L14,10 L10,10 L10,14 L12.0218,14 C11.7253,14.4632 11.4858,14.9665 11.3135,15.5 L10,15.5 L10,19.5 L11.3135,19.5 C11.4858,20.0335 11.7253,20.5368 12.0218,21 L6.25,21 C4.45507,21 3,19.5449 3,17.75 L3,6.25 C3,4.45507 4.45507,3 6.25,3 L17.75,3 Z M15.1464,15.1464 C14.9512,15.3417 14.9512,15.6583 15.1464,15.8536 L16.7929,17.5 L15.1464,19.1464 C14.9512,19.3417 14.9512,19.6583 15.1464,19.8536 C15.3417,20.0488 15.6583,20.0488 15.8536,19.8536 L17.5,18.2071 L19.1464,19.8536 C19.3417,20.0488 19.6583,20.0488 19.8536,19.8536 C20.0488,19.6583 20.0488,19.3417 19.8536,19.1464 L18.2071,17.5 L19.8536,15.8536 C20.0488,15.6583 20.0488,15.3417 19.8536,15.1464 C19.6583,14.9512 19.3417,14.9512 19.1464,15.1464 L17.5,16.7929 L15.8536,15.1464 C15.6583,14.9512 15.3417,14.9512 15.1464,15.1464 Z M8.5,15.5 L4.5,15.5 L4.5,17.75 C4.5,18.668175 5.20710875,19.4211925 6.10647256,19.4941988 L6.25,19.5 L8.5,19.5 L8.5,15.5 Z M8.5,10 L4.5,10 L4.5,14 L8.5,14 L8.5,10 Z M8.5,4.5 L6.25,4.5 C5.2835,4.5 4.5,5.2835 4.5,6.25 L4.5,8.5 L8.5,8.5 L8.5,4.5 Z M17.75,4.5 L15.5,4.5 L15.5,8.5 L19.5,8.5 L19.5,6.25 C19.5,5.331825 18.7928913,4.5788075 17.8935274,4.50580119 L17.75,4.5 Z M14,4.5 L10,4.5 L10,8.5 L14,8.5 L14,4.5 Z"
									id="🎨-Color"></path>
							</g>
						</g>
					</g>
				</svg>
			</button>
			}
		</div>
		<tiptap-editor class="focus-visible:outline-none" [editor]="editor" [formControl]="formControl" [ariaDisabled]="readonly"></tiptap-editor>
	</form>
</div>
