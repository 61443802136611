import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MessageHandler } from 'addiction-components';
import { catchError, map, of, switchMap } from 'rxjs';
import { ObBuilderActions } from 'src/app/core/state/app.actions';
import { OBTailSelectors } from 'src/app/core/state/app.selectors';
import { ObBuilderService } from '../services/ob-builder.service';

@Injectable()
export class OBTailEffect {
	private tails$ = inject(Actions);
	private obBuilderSrv = inject(ObBuilderService);
	private store = inject(Store);
	private messageHandler = inject(MessageHandler);
	private translateService = inject(TranslateService);

	loadTails$ = createEffect(() =>
		this.tails$.pipe(
			ofType(
				ObBuilderActions.loadTails,
				ObBuilderActions.reloadTails,
				ObBuilderActions.createTailSuccess,
				ObBuilderActions.updateTailSuccess,
				ObBuilderActions.deleteTailSuccess,
				ObBuilderActions.setActiveType
			),
			concatLatestFrom(() => this.store.select(OBTailSelectors.selectTailType)),
			switchMap(([, tailType]) => {
				return this.obBuilderSrv.getTails({ type: tailType }, this.translateService.currentLang).pipe(
					map((tails) => {
						return ObBuilderActions.loadTailsSuccess({ tails });
					}),
					catchError((error) => of(ObBuilderActions.loadTailsFailure({ error })))
				);
			})
		)
	);

	// UPDATE ONE
	updateTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.updateTail),
			switchMap(({ tail }) =>
				this.obBuilderSrv.updateTail(tail).pipe(
					map((tail) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObBuilderActions.updateTailSuccess({ tail });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObBuilderActions.updateTailFailure({ error }));
					})
				)
			)
		)
	);

	// CREATE ONE
	createTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.createTail),
			switchMap(({ tail, parentUuid }) =>
				this.obBuilderSrv.createTail(tail).pipe(
					map((tail) => {
						this.messageHandler.createToast('ALERT.SUCCESS_SAVE_MESSAGE');
						return ObBuilderActions.createTailSuccess({ tail, parentUuid });
					}),
					catchError((error) => {
						this.messageHandler.createErrorToast('ALERT.ERROR_SAVE_MESSAGE');
						return of(ObBuilderActions.createTailFailure({ error }));
					})
				)
			)
		)
	);

	// DELETE ONE
	deleteTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.deleteTail),
			switchMap(({ uuid }) =>
				this.obBuilderSrv.deleteTail(uuid).pipe(
					map(() => ObBuilderActions.deleteTailSuccess({ uuid })),
					catchError((error) => of(ObBuilderActions.deleteTailFailure({ error })))
				)
			)
		)
	);

	selectedTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.changeTailSelected),
			map(({ tail }) => ObBuilderActions.selectTail({ tail }))
		)
	);

	selectedEditableTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.changeTailSelected),
			map(({ tail }) => ObBuilderActions.editTail({ uuid: tail.uuid }))
		)
	);

	editNewTail$ = createEffect(() =>
		this.tails$.pipe(
			ofType(ObBuilderActions.createNewTail),
			map(() => ObBuilderActions.editTail({ uuid: 'new' }))
		)
	);
}
