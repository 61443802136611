import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { StructureType, ToLocalizedValuePipe } from 'addiction-components';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, debounceTime, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunicationActions, CommunitySelectActions } from 'src/app/core/state/app.actions';
import { CommunicationSelector, CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models';
import { CommunicationService } from '../services/communication.service';
import { setCommunicationsSuccess } from './communication.actions';
import { selectPagedData } from './communication.selectors';

@Injectable()
export class CommunicationEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private communicationService = inject(CommunicationService);
	private translateService = inject(TranslateService);
	private localizablePipe = inject(ToLocalizedValuePipe);
	private activeRoute = inject(Router);

	fetchCommunications$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(CommunicationActions.setPages, CommunicationActions.setForceRefreshCommunications),
			concatLatestFrom(() => [
				this.store.select(CommunicationSelector.selectPages),
				this.store.select(CommunicationSelector.selectSort),
				this.store.select(CommunicationSelector.selectGridSearch),
				this.store.select(CommunicationSelector.selectCommunicationsFilters),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(CommunicationSelector.selectCachedPages),
			]),
			debounce(() => this.store.select(CommunicationSelector.selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(CommunicationActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) {
					this.store.dispatch(CommunicationActions.setStatus({ status: STATE_STATUS.READY }));
					return EMPTY;
				}
				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				return this.communicationService
					.fetchCommunications(actualPages, sort, this.translateService.currentLang, gridSearch, filterWithCommunity)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalCommunicationsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.communications[item.paginationInfo.pageNumber] = item.result.map((communication) => ({
											...communication,
											features:
												communication.featureValueList?.map((f) =>
													typeof f === 'string' ? '' : this.localizablePipe.transform(f.label || f.name)
												) ?? [],
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							return CommunicationActions.setCommunicationsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(CommunicationActions.setCommunicationsError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CommunicationActions.setGridSearch,
				CommunicationActions.setSort,
				CommunicationActions.updateFilters,
				CommunicationActions.addFeatureValueToFilters,
				CommunicationActions.removeFeatureValueFromFilters
			),
			concatLatestFrom(() => [
				this.store.select(CommunicationSelector.selectPages),
				this.store.select(CommunicationSelector.selectSort),
				this.store.select(CommunicationSelector.selectGridSearch),
				this.store.select(CommunicationSelector.selectCommunicationsFilters),
				this.store.select(CommunicationSelector.selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, filterPrev], [, pagesCurr, sortCurr, gridSearchCurr, filterCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						Object.values(filterPrev).every((f) => Object.values(filterCurr).includes(f)) &&
						Object.values(filterCurr).every((f) => Object.values(filterPrev).includes(f)) &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => CommunicationActions.resetCache())
		)
	);

	deleteCommunication$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.setDeleteCommunication),
			// invoke API
			switchMap(({ communication }) => {
				return this.communicationService.deleteCommunication(communication.uuid, StructureType.COMMUNICATION).pipe(
					map(() => {
						this.communicationService.getStructureAndFeatures(StructureType.COMMUNICATION);
						return CommunicationActions.setDeleteCommunicationSuccess();
					}),
					catchError((error: HttpErrorResponse) => of(CommunicationActions.error({ error: { errorType: 'generic_http', data: error } })))
				);
			})
		)
	);

	deleteCommunications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.deleteCommunications),
			// invoke API
			switchMap(({ uuids }) =>
				this.communicationService
					.multipleUpdate({
						uuidList: uuids,
						active: false,
					})
					.pipe(
						map(() => CommunicationActions.setDisableCommunicationsSuccess()),
						catchError((error: HttpErrorResponse) => of(CommunicationActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.communicationService.getStructureAndFeatures(StructureType.COMMUNICATION))
					)
			)
		)
	);

	disableCommunications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.disableCommunications),
			// invoke API
			switchMap(({ uuids }) =>
				this.communicationService
					.multipleUpdate({
						uuidList: uuids,
						active: false,
					})
					.pipe(
						map(() => CommunicationActions.setDisableCommunicationsSuccess()),
						catchError((error: HttpErrorResponse) => of(CommunicationActions.error({ error: { errorType: 'generic_http', data: error } }))),
						tap(() => this.communicationService.getStructureAndFeatures(StructureType.COMMUNICATION))
					)
			)
		)
	);

	communicationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCommunicationsSuccess),
			map(({ data: { pages } }) => CommunicationActions.setCachedPages({ pages }))
		)
	);

	deleteAndDisableCommunication$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				CommunicationActions.setDeleteCommunicationSuccess,
				CommunicationActions.setDeleteCommunicationError,
				CommunicationActions.setDisableCommunicationsSuccess,
				CommunicationActions.setDisableCommunicationsError
			),
			map(() => CommunicationActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunicationActions.resetCache),
			tap(() => {
				this.store.dispatch(CommunicationActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			debounceTime(500),
			map(() => CommunicationActions.setForceRefreshCommunications()),
			tap(() => {
				this.store.dispatch(CommunicationActions.setStatus({ status: STATE_STATUS.READY }));
			})
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url === '/communications'),
			map(() => CommunicationActions.resetCache())
		)
	);

	constructor() {}
}
