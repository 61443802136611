import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { STATE_STATUS } from 'src/app/shared/models';
import { RolesService } from 'src/app/shared/services/roles.service';
import { selectPages, selectSort, selectCachedPages, selectGridSearch, selectStatus, selectPagedData } from './roles.selectors';
import { setDeleteRoles, setDeleteRolesError, setDeleteRolesSuccess, setForceRefreshRoles, setPages, setRolesError, setRolesSuccess } from './roles.actions';
import { CommunitySelectActions, RolesActions } from 'src/app/core/state/app.actions';
import { Router } from '@angular/router';

@Injectable()
export class RolesEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private rolesService = inject(RolesService);
	private activeRoute = inject(Router)

	fetchRoles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setPages, setForceRefreshRoles),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			debounce(() =>
				this.store.select(selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
			),
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(RolesActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.rolesService.fetchRoles(actualPages, sort, gridSearch).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;

						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalRolesCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.roles[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return setRolesSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setRolesError({ error }));
					})
				);
			})
		)
	);

	deleteRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteRoles),
			switchMap(({ role }) => {
				return this.rolesService.deleteRole(role).pipe(
					map(() => {
						return setDeleteRolesSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteRolesError({ error }));
					})
				);
			})
		)
	);

	afterDeleteRoles$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesActions.setDeleteRolesSuccess, RolesActions.setDeleteRolesError),
			map(() => RolesActions.resetCache())
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				RolesActions.setGridSearch,
				RolesActions.setSort,
				RolesActions.addFeatureValueToFilters,
				RolesActions.removeFeatureValueFromFilters,
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, filterPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, filterCurr]
				) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						filterPrev === filterCurr &&
						gridSearchCurr === gridSearchPrev
					);
				}
			),
			map(() => RolesActions.resetCache())
		)
	);

	roleSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setRolesSuccess),
			map(({ data: { pages } }) => RolesActions.setCachedPages({ pages }))
		)
	);

	deleteAndDisableRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				RolesActions.setDeleteRolesSuccess,
				RolesActions.setDeleteRolesError,
				RolesActions.setDisableRolesSuccess,
				RolesActions.setDisableRolesError
			),
			map(() => RolesActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(RolesActions.resetCache),
			map(() => RolesActions.setForceRefreshRoles())
		)
	);

	refreshCommunity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CommunitySelectActions.setLastCommunitySelected),
			filter(() => this.activeRoute.url.length > 0 && this.activeRoute.url.includes('roles')),
			map(() => RolesActions.resetCache())
		)
	);

	constructor() {}
}
